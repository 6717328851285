export default {
    name: 'ArchiveCount',
    data() {
        return {
            query:{},
            categoryList: [],
            start_time: '',
            end_time: '',
            category: '',
            items: [],
            historyData:[],
            category_id:'2',
            page_number:15,
            page:1,
            total:0,
            columns: [
                // {
                //     title: '序号',
                //     type: index
                // },
                {
                    title: '类别1',
                    key: 'category1',
                    align: 'center',
                    width: 280
                },
                {
                    title: '类别2',
                    key: 'category2',
                    align: 'center',
                    width: 280
                },
                {
                    title: '文件名称',
                    key: 'file_name',
                    align: 'center',
                    width: 330
                },
                {
                    title: '经办人',
                    key: 'handler',
                    align: 'center',
                },
                {
                    title: '删除日期',
                    key: 'del_date',
                    align: 'center',
                },
                {
                    title: '还原/删除',
                    align: 'center',
                    slot: 'op'
                },
            ],
            roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),
        }
    },

    created() {
        let route = this.$route.query;
        // this.page = route.page || 1;
        // this.start_time = route.start_time;
        // this.end_time = route.end_time;
        this.category = route.category|| ''
    },

    mounted() {
        this.categorys();
        this.recycle();
    },


    methods: {
        // 文件回收站列表
        recycle(){
            this.$api.archives.recycle({
                begin_date:this.start_time,
                end_date:this.end_time,
                categoryid:this.category_id,
                page:this.page,
                page_number:this.page_number
            }).then(res=>{
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.items=res.list;

                if(res.list.length < this.page_number){
                    this.historyData = res.list;
                }else{
                    this.historyData = res.list.slice(0,this.page_number);
                }
            })
        },
        changepage(index){
            let _start = ( index - 1 ) * this.page_number;
            let _end = index * this.page_number;
            this.historyData = this.items.slice(_start,_end);
        },
        changeId(e){
            this.category_id=e;
            this.categoryList.filter((item)=>{
                if(item.category_id==e){
                    if(document.querySelector('.ivu-select-selected-value')==undefined){
                        document.querySelector('.ivu-select-placeholder').innerText=item.category_name;
                        return;
                    }
                    document.querySelector('.ivu-select-selected-value').innerHTML=item.category_name;
                }
            })

        },
        // 选择时间
        onChangeTime(v) {
            this.start_time = v[0];
            this.end_time = v[1]
        },

        categorys(){
            this.$api.archives.categoryList({category_tag:'archive_search'}).then(res => {
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.categoryList = res.list
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },



        // 搜索
        search() {
            this.page = 1;
            this.$router.push({
                name: 'ArchiveRecovery',
                query:{
                    category: this.category,
                    start_time: this.start_time,
                    end_time: this.end_time,
                    page: 1
                }
            })
        },
        // 重置
        reset(){
            this.start_time = '';
            this.end_time = '';
            this.category_id=2;
            if(document.querySelector('.ivu-select-selected-value')==undefined) return;
            document.querySelector('.ivu-select-selected-value').innerHTML='商务协议'
            this.recycle();
        },
        haddleEvent(state,row) {
            this.$Modal.confirm({
                title: '提示',
                content: state?'是否需要把附件还原至原路径？':'是否需要删除附件，删除后不可恢复？',
                onOk:()=>{
                    if(state){
                        //还原操作
                        this.$api.archives.recover({
                            file_ids:[Number(row.id)]
                        }).then(res => {
                            if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg });
                            this.$Message.success({content: '附件还原成功'})
                            this.recycle();

                        })

                    }else{
                        //删除操作
                        this.$api.archives.delete({
                            delete_files:[Number(row.id)]
                        }).then(res => {
                            if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg });
                            this.$Message.success({content: '附件删除成功'})
                            this.recycle();
                        })


                    }
                }
            })
            if(state){
                //还原

            }
        }
    }
}